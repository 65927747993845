@import "app/colours.css";

.SettingsScreen {
    max-width: 480px;
    margin: 0 auto;
}

.header {
    font-size: 1.5rem;
    font-weight: normal;
    padding: 2rem;
    border-bottom: 1px solid #ccc;
}

.alert svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.alert path {
    fill: var(--warning-400);
}

.venueData {
    margin: 2rem 1rem;
}

.venueData td:first-of-type {
    width: 7rem;
}

/* Buttons */
.settingsButton {
    border: 1px solid var(--header-colour);
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    margin: 0 0 2rem 0;
}