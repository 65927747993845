.Loader {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Loader img {
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
}