@import "app/breakpoints.scss";
@import "LoginText.module.scss";
@import "app/colours.css";

.loginError {
    font-style: italic;
    padding: 0.5rem 1rem;
}

.loginErrorView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    margin: 0 auto 1rem auto;
    font-size: 1.25rem;
    padding: 0rem;
    text-align: left;

    @include tablet-portrait {
        max-width: 900px;
        text-align: center;
    }
}

.loginErrorView h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0rem 0 1rem 0;
    color: var(--chomp-action);
    text-align: center;
}

.support {
    font-size: 1rem;
}

.errorImage {
    height: 120px;
    margin: 1rem auto;
}