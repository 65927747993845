@import "./Form.module.css";

.DateEntry {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input {
    flex-grow: 1;
    padding: var(--form-control-padding);
    cursor: pointer;
    width: 100%;
}

.inputContainer {
    display: flex;
    position: relative;
}

/* When not in a row */
form > .DateEntry .inputContainer {
    max-width: 17rem;
}

.calendar {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 0.45rem;
    top: 0.9rem;
    pointer-events: none; 
}