@import "app/colours.css";

.verySmallIcon {
    width: 0.75rem;
    height: 0.75rem;
}

.smallIcon {
    width: 1rem;
    height: 1rem;
}

.largeIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.success {}

.success path {
    fill: var(--success-pressed);
}

.info {}

.info path {
    fill: var(--main-tint);
}

.detail {}

.detail path {
    fill: var(--neutral700);
}

.warning {}

.warning path {
    fill: var(--warning-400);
}