.CloseButton {
    display: block;
    background-color: #fff;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}