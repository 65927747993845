@import "app/colours.css";

.DeviceId {
    padding: 0.1rem;
    display: flex;
}

.DeviceId h3 {
    font-size: 0.700rem;
    padding: 0;
    margin: 0;
    color: var(--neutral700);
}

.DeviceId p {
    font-size: 0.875rem;
    margin: 0;
}

.DeviceId svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

