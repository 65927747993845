@import "app/colours.css";
@import "app/breakpoints.scss";

:root {
    --header-colour: #696F8C;
}

.Header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    color: var(--header-colour);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @include tablet-portrait {
        box-shadow: none;
    }
}

.Header h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
}

.venueName {
    display: flex;
    flex-grow: 1;
}

.logoContainer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.logo {
    display: none;

    @include tablet-portrait {
        display: flex;
        height: 3rem;
    }
    
}

.currentUserLable {
    display: none;

    @include tablet-landscape {
        display: flex;
    }
}

.version {
    font-size: 0.75rem;
    margin: 0.25rem 0 0 0;
    text-align: right;
    color: var(--neutral400);
}

.staffVersionContainer {
    display: none;

    @include tablet-landscape {
        display: block;
        position: relative;
        top: 0.75rem;
    }
}

/* Buttons */
.headerButton {
    border: 1px solid var(--header-colour);
    border-radius: 4px;
    align-items: center;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60vw;
    white-space: nowrap;

    @include tablet-portrait {
        display: flex;
        max-width: unset;
    }
}

.headerButton svg {
    width: 16px;
    height: 16px;
    margin-right: 1rem;
}

.toastsContainer {
    position: absolute;
    top: -0.5rem;
    right: 0rem;
    width: 27rem;
    z-index: 1;
}

.toastsContainer svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
}

.toast {
    width: 100% !important;
}

.success {
    background-color: var(--success-light-bg) !important;
}

.success path {
    fill: var(--success-pressed);
}

.successText {
    color: var(--success-pressed) !important;
}

.venueName {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.venueName {
    .headerButton::after {
        content: "▼";
        transform: scale(1, 0.5);
        margin-left: 1rem;
    }
}

.clickable {
    cursor: pointer;
}

.venueSelect {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 0;
    text-align: left;
    flex-grow: 1;
    max-width: 75%;

    @include tablet-portrait {
        max-width: unset;
    }
}

.venueSelect select {
    @include tablet-portrait {
        width: unset;
    }
}