@import "app/colours";

.ProfileScreen {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding: 0 20px;
    margin: 2rem auto;
}

.ProfileScreen h1 {
    font-size: 2.5rem;
    display: flex;
    align-items: center;
}

.ProfileScreen h3 {
    font-size: 1rem;
    font-weight: bold;
}

.userIcon {
    display: flex;
    margin: 1rem;
    background-color: var(--neutral400);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    
}

.controls {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}

.controls div {
    display: flex;
    justify-content: space-between;
}

.controls button {
    width: 250px;
}

.details {
    margin-left: calc(60px + 2rem);
}