@import "app/colours.css";

.ButtonComponent {
    color: var(--main-tint);
    background-color: #fff;
    border: 1px solid var(--main-tint)
}

.active {
    color: #fff;
    background-color: var(--main-tint);
}