:root {
    --main-tint: #0066FF;
    --main-tint-light: #d5d8dc;
    --chomp-action: #f36523;
    --neutral: #F9FAFC;
    --neutral400: #D8DAE5;
    --neutral700: #696F8C;
    --success-light-bg: #DCF2EA;
    --success-pressed: #006E4A;
    --warning-light-bg: #FFF7D0;
    --warning-pressed: #8C5100;
    --warning-400: #D48C00;
    --problem: coral;
    --invalid: #d40e0e;
    --error: #d40e0e;
    --error-light-bg: #f6a1a1;
    --overdue-background: #F47452;
    --overdue-status-background: rgb(255, 60, 0);
}