:root {
    --event-colour: #006CA8;
    --event-gradient-start: #0C78B5;
    --urgent-colour: #E75B00;
    --urgent-gradient-start: #FC6400;
    --due-colour: #00825D;
    --due-gradient-start: #429777;
}

.taskGroup {
    padding: 1em;
    border-radius: 1rem;
    box-shadow: 0px 4px 14px rgba(36, 44, 60, 0.25);
    min-height: 112px;
    flex: 1 1 0;
    flex-basis: 25%;
    flex-grow: 0;
    color: white;
    cursor: pointer;
}

.header {
    display: flex;
    align-items: center;
    margin: 0 0 0.2rem 0;
}

.icon {
    display: flex;
}

.icon > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    fill: #ffffff;
    opacity: 0.5;
}

.groupName {
    font-size: 1.5rem;
    font-weight: bold;
}

.groupCount {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    opacity: 0.5;
    margin: 0;
}

@media screen and (max-width: 1000px) {
    .taskGroup {
        flex-basis: 40%;
    }
}

.due {
    background: radial-gradient(67.05% 185.78% at 97.67% 100%, var(--due-gradient-start) 0%, var(--due-colour) 100%);

}

.urgent {
    background: radial-gradient(67.05% 185.78% at 97.67% 100%, var(--urgent-gradient-start) 0%, var(--urgent-colour) 100%);
}

.event {
    background: radial-gradient(67.05% 185.78% at 97.67% 100%, var(--event-gradient-start) 0%, var(--event-colour) 100%);

}

@keyframes fadeOut {
    0% { opacity: 1}
    100% { opacity: 0; }
}
  
.fadeOut{
    animation: fadeOut .3s forwards;
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}