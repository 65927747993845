@import "app/colours.css";

.NetworkStatus {
    padding: 1rem;
    display: flex;
}

.NetworkStatus h3 {
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
}

.NetworkStatus p {
    font-size: 0.875rem;
    margin: 0;
}

.NetworkStatus svg {
    margin-right: 0.5rem;
}

.online {
    background-color: var(--success-light-bg);
    color: var(--success-pressed);
}

.offline {
    background-color: var(--warning-light-bg);
    color: var(--warning-pressed);
}
