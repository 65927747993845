.icon {
    display: flex;
    justify-content: center;
}

.VenueClosed {
    text-align: center;
    height: 300px;
}

.VenueClosed h1 {
    font-size: 2rem;
    font-weight: normal;
    margin-bottom: 1rem;
}