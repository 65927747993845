@import "app/colours.css";

.TabOptions {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.TabOptions > p {
    margin-bottom: 0.5rem;
}

.TabOptions :global(.nav) {
    margin-top: 0;
    justify-content: flex-start;
}

.TabOptions :global(.nav-item) {
    background-color: white;
}

.control {
    display: flex;
    align-items: center;
}

.control svg {
    margin-left: 1rem;
}

.problem :global(.active) {
    background-color: var(--problem) !important;
}