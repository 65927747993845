@import "./Form.module.css";
@import "app/colours.css";

.TimeEntry {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input {
    padding: var(--form-control-padding);
    cursor: pointer;
    width: 100%;
}

/* hide custom clock icon in non-safari browsers */
.clock {
    display: none;
}

/* Safari only css hack
   See: https://stackoverflow.com/a/25975282/222534 
*/
_::-webkit-full-page-media, _:future, :root .input {
    padding: 0;
    background-color: white;
    -webkit-appearance : none;
    border-radius: 2px;
    border: thin solid var(--neutral700);
}
/* Safari time-input value styling */
.TimeEntry input::-webkit-date-and-time-value,
.TimeEntry input::-webkit-datetime-edit {
    background-color: white;
    text-align: left;
    padding: var(--form-control-padding);
    color: black;
    font-size: 1rem;
}
/* Show clock in safari */
_::-webkit-full-page-media, _:future, :root .clock {
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 3rem;
    pointer-events: none; 
}

.problem .input {
    color: var(--problem);
}

.invalid .input {
    color: var(--invalid);
}

.inputContainer {
    display: flex;
    position: relative;
}

/* When not in a row */
form > .TimeEntry .inputContainer {
    max-width: 17rem;
}

