@import "app/colours.css";

.checkbox {}

[type=checkbox].checkbox {
    width: 2rem;
    height: 2rem;
    color: var(--main-tint);
    vertical-align: middle;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 4px;
    background-color: #FFFFFF;
    transition: background-color 150ms;
    cursor: pointer;
}

/* Pseudo element for check styling */

[type=checkbox].checkbox::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px var(--main-tint);
}


/* Checked */

[type=checkbox].checkbox:checked {
    background-color: currentcolor;
}

[type=checkbox].checkbox:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}


/* Disabled */

[type=checkbox].checkbox:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
}  