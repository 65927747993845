@import "app/breakpoints.scss";

.dueScreen {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include tablet-portrait {
        grid-template-columns: 1fr 1fr;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
    }
}

.groupHeading {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
    justify-content: flex-start;
    margin: 2rem 0;
}

.groupNotification {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay {
    width: 80vw;
    max-height: 90vh;
    max-width: 930px;
    margin: 5% auto;
    background-color: #fff;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
}

.modalContent {
    position: fixed;
    margin: 0 auto;

    @include tablet-portrait {
        position: unset;
        margin: 3% auto auto auto;
    }
}

// Segments

.activeSegmentContainer {
    display: flex;
    justify-content: center;
    position: relative;
    top: -2rem;
}

.activeSegmentName {
    display: flex;
    align-items: center;
    border: 1px solid var(--header-colour);
    border-radius: 4px;
    align-items: center;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90vw;
    white-space: nowrap;
    margin-top: 2rem;

    @include tablet-portrait {
        display: flex;
        max-width: unset;
        width: unset;
        margin-top: unset;
    }
}

.activeSegmentName::after {
        content: "▼";
        transform: scale(1, 0.5);
        margin-left: auto;

        @include tablet-portrait {
            margin-left: 1rem;
        }
}