@import "app/breakpoints.scss";
@import "LoginText.module.scss";
@import "app/colours.css";

:global(.loginScreen) h2 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1em auto;
}
:global(.nav) {
    margin-top: 2rem;
    justify-content: center;
}

:global(.alert) {
    width: 400px;
    margin: 1rem auto;
}

.logo {
    height: 6.5rem;
    position: relative;
    top: -2px;
}

.venueKeyForm {
    margin: 2rem auto;

    @include tablet-portrait {
        width: 400px;
    }
}

.venueKeyForm button {
    margin-top: 2rem;
    width: 100%;
}

.loginLoader {
    margin-top: 1rem;
}

button.cancel {
    color: var(--neutral600);
    background-color: var(--neutral400);
}

.passwordReset {
    display: block;
    margin-top: 1rem;
    text-align: right;
}