.titleContainer {
    padding: 1.5rem;
    border-bottom: 1px solid #aaa;
}

.titleContainer h2 {
    text-align: left;
    font-size: 1.5rem;
}

.formContainer {
    padding: 0;
}