@import "app/colours.css";

.IntegerEntry { 
    margin-bottom: 1rem;
}

.IntegerEntry input {
    max-width: 15rem;
    border: 1px solid var(--neutral700);
}

.control {
    display: flex;
}

.unitControl input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    text-align: right;
}

.unitControl input[type=number]::-webkit-outer-spin-button, 
.unitControl input[type=number]::-webkit-inner-spin-button { 
    margin-left: 0.5rem; 
} 

.unit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 50px;
    background-color: var(--neutral400);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid var(--neutral700);
    border-left: 0;
    padding: 0.5rem;
}