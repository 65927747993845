@import "app/breakpoints.scss";

.PasswordReset {
    margin: 2rem auto;

    @include tablet-portrait {
        width: 500px;
    }
}

.PasswordReset button {
    margin-top: 2rem;
    width: 100%;
}

.loginLoader {
    margin-top: 1rem;
}

.logo {
    display: block;
    margin: 1rem auto 3rem auto;
    height: 6.5rem;
}

.instructions {
    margin: 2rem 0;    
}