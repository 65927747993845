@import "app/breakpoints.scss";

:global(html) {
    font-size: 16px;
}

.AppLogin {}

.App {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr;
}

.activeScreen {
    overflow: auto;
    padding: 1rem;

    @include tablet-portrait {
        padding: 2rem;
    }
}

.toastsContainer {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 90vw;
    z-index: 1;

    @include tablet-portrait {
        width: 27rem;
    }
}

.toastsContainer svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
}

.toast {
    width: 100% !important;
}

.success {
    background-color: var(--success-light-bg) !important;
}

.success path {
    fill: var(--success-pressed);
}

.successText {
    color: var(--success-pressed) !important;
}

.warning {
    background-color: var(--warning-light-bg) !important;
}

.warning path {
    fill: var(--warning-pressed);
}

.warningText {
    color: var(--warning-pressed) !important;
}

.error {
    background-color: var(--error-light-bg) !important;
}

.error path {
    fill: var(--error);
}

.errorText {
    color: var(--error) !important;
}