@import "./Form.module.css";
@import "app/colours.css";

.DropdownList {
    margin-bottom: 1.5rem;
}

.customValue {
	margin-left: 2rem;
}

.dropdown {
    padding: var(--form-control-padding);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #fff;
    border-radius: 2px;
}

.problem {
    border: 2px solid var(--problem);
    outline-color: var(--problem);
}

.invalid {
    border: 2px solid var(--invalid);
}