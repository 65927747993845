.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    z-index: 10;
}

.selector {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 300px;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 1rem;
    max-height: 80vh;
    overflow: auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.selector.right {
    right: 1rem;
    left: unset;
}

.selector.center {
    left: 50%;
    transform: translateX(-50%);
}

.itemList {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.itemList li {
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.itemList li:last-of-type {
    border-bottom: 0;
}

.activeItem {
    font-weight: bold;
}