.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    z-index: 10;
}

.staffSelector {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 300px;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 1rem;
    max-height: 80vh;
    overflow: auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.staffList {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.staffList li {
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.staffList li:last-of-type {
    border-bottom: 0;
}

.activeUser {
    font-weight: bold;
}