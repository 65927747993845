$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 991px;
$desktop-min: 992px;
$large-desktop-min: 1200px;

:root {
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
  --large-desktop-min: #{$large-desktop-min};
}

// Mobile only
@mixin mobile {
  @media only screen and (max-width: $mobile-max) {
    @content;
  }
}

// Tablet portrait and up
@mixin tablet-portrait {
  @media only screen and (min-width: $tablet-min) {
    @content;
  }
}

// Tablet landscape and up
@mixin tablet-landscape {
  @media only screen and (min-width: $tablet-max) {
    @content;
  }
}

// Desktop and up
@mixin desktop {
  @media only screen and (min-width: $desktop-min) {
    @content;
  }
}

// Large desktop and up
@mixin large-desktop {
  @media only screen and (min-width: $large-desktop-min) {
    @content;
  }
}
