.container {
    width: fit-content;
    text-align: center;
}

.container a:link {
    color: var(--chomp-action);
    text-decoration: none;
    font-weight: bold;
}

.container a:visited {
    color: var(--chomp-action);
    text-decoration: none;
    font-weight: bold;
}

.container a:hover {
    color: var(--chomp-action);
    text-decoration: none;
    font-weight: bold;
}

.container a:active {
    color: var(--chomp-action);
    text-decoration: none;
    font-weight: bold;
}