@import "app/breakpoints.scss";
@import "LoginText.module.scss";

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 1rem auto;

    @include tablet-portrait {
        flex-direction: row;
    }
}

.text {
    text-align: center;
    max-width: 600px;
    margin: 2rem auto;
}

.text p {
    margin-bottom: 3rem;
}

.button {
    padding: 1rem 1rem;
    width: 230px;
    background-color: var(--chomp-action);
    color:#fff;
    margin-bottom: 2rem;

    @include tablet-portrait {
        margin-bottom: 0;
    }
}

.button:first-of-type {
    @include tablet-portrait {
        margin-right: 1rem;
    }
}
