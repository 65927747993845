@import "app/colours.css";

:root {
    --form-control-padding: 0.6rem 0.5rem;
}

.label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: normal;
}

.invalid {
    color: var(--invalid);
}

.error {
    color: var(--error);
}

.errorNumberSlider {
    color: var(--error);
    text-align: right;
}

.problem {
    color: var(--problem);
}

.errorTextEntry {
    border: 1px solid var(--error);
}

.heading {
    font-weight: bold;
    font-size: 1.25rem;
    text-align: left;
    margin-bottom: 1.5rem;
}

.subheading {
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
}

.problem .subheading {
    color: var(--problem);
}

.description {
    font-size: 1rem;
    color: var(--neutral700);
    max-width: 700px;
    margin-bottom: 0;
}

.description ul {
    margin: 0;
    padding-left: 1rem;
}

.description p {
    margin-bottom: 0;
}

.input {
    padding: var(--form-control-padding) !important;
    border-width: thin;
    border-style: solid;
    border-radius: 2px;
}

.bgHighlight {
    background-color: var(--main-tint-light);
}

.cursorPointer {
    cursor: pointer;
}