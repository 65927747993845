@import "app/colours.css";

.NumberSlider {
    margin-bottom: 1.5rem;
}

.header {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.controls {
    display: flex;
}

.number {
    width: 84px;
    height: 56px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--neutral700);
    cursor: pointer;
}

:global(.react-tiny-popover-container) {
    z-index: 10;
}

.popoverList {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.popoverList ul {
    list-style: none;
    padding: 0;
    overflow: auto;
    max-height: 90vh;
}

.popoverList li {
    padding: 1rem 2rem;
    border-bottom: 1px solid #D8DAE5;
    cursor: pointer;
}

.number:last-of-type {
    border: 0;
}

.slider {
    display: flex;
    overflow: scroll;
    border: 1px solid var(--neutral700);
    border-radius: 4px;
}

.temperature {
    display: flex;
    border: 1px solid var(--neutral700);
    border-radius: 4px;
    justify-content: center;
    align-items: stretch;
    width: 140px;
    margin-left: 1rem;
    flex-shrink: 0;
}

.problem .temperature {
    border: 1px solid var(--problem);
}

.valid .temperature {
    border: 1px solid var(--success-pressed);
}

.unit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 50px;
    background-color: var(--neutral400);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.error .unit {
    font-weight: bold;
    color: #ffffff;
    background-color: var(--error);
}

.problem .unit {
    font-weight: bold;
    color: #ffffff;
    background-color: var(--problem);
}

.valid .unit {
    font-weight: bold;
    color: var(--success-pressed);
    background-color: var(--success-light-bg);
}

.value {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: end;
    width: 100%;
    border: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.problem .value {
    color: var(--problem);
}

.valid .value {
    color: var(--success-pressed);
}

.selected {
    background-color: var(--main-tint);
    color: white;
    font-weight: bold;
}

.problem .selected {
     background-color: var(--problem);
}

.label {
    font-size: 1rem;
    font-weight: normal;
    margin-right: 1rem;
}

/* Sensor */
.sensorStatusContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.sensorStatus {
    display: flex;
    align-items: baseline;
    background-color: var(--success-light-bg);
    color: var(--success-pressed);
    font-size: 0.9rem;
    padding: 2px 5px;
    border-radius: 4px;
}

.sensorProblem {
    background-color: var(--problem);
    color: white;
}

.warningIcon {
    width: 1rem;
    fill: var(--error);
    margin: auto 0.5rem auto 0;
}

.infoIcon {
    border-radius: 50%;
    background-color: var(--problem);
    width: 1.5rem;
    text-align: center;
    color: #ffffff;
    margin-left: 0.5rem;
    font-weight: bold;
    cursor: pointer;
}

.popoverSensorMessage {
    width: 20rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: var(--error);
    color: #ffffff;
    margin: 0;
}
