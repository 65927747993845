@import "app/colours";

.ProfileForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding: 0 20px;
    margin: 2rem auto;
}

.controls {
    display: flex;
    justify-content: space-between;
}

.cancel {
    color: var(--neutral600);
    background-color: var(--neutral400);
}