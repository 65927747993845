@import "app/colours";

.InviteProfile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 0 20px;
  margin: 2rem auto;
}


.logoContainer {
    text-align: center;
    margin-bottom: 4rem;
}

.logo {
    height: 6.5rem;
}

.buttons {
    // margin-top: 2rem;
}

