@import "app/breakpoints.scss";
@import "app/colours.css";

.CameraCapture {
    min-width: 50vw;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: #ccc;
    color: black;

    @include tablet-portrait {
        max-width: 80vw;
    }
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    flex-flow: column-reverse;

    @include tablet-portrait {
        height: 80px;
        flex-direction: row;
        flex-flow:unset;
    }
}

.controls button,
.controls select {
    width: 100%;
    margin-bottom: 1.5rem;

    @include tablet-portrait {
        width: unset;
        margin-bottom: 0;
    }
}

.cancel {
    color: var(--neutral600);
    background-color: var(--neutral400);
}

.CameraCapture video {
    max-height: 75vh;
}

.captureButton {
    display: flex;
    justify-content: center;
}