@import "app/colours.css";
@import "app/breakpoints.scss";

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    z-index: 2000;
}

.InviteUser {
    background-color: var(--neutral);
    overflow: auto;
    margin: auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 80vw;

    @include tablet-portrait {
        max-height: 95vh;
        max-width: 600px;
    }

    @include tablet-landscape {
        width: 80vw;
        max-height: 90vh;
    }
}

.heading {
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid var(--neutral400);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--neutral);
    z-index: 2;
}

.heading:first-child:nth-last-child(2) {
    border-bottom: none;
}

.heading svg {
    width: 2rem
}

.content {
    padding: 1.5rem;
}

.InviteUser h3 {
    font-size: 1rem;
}

.InviteUser footer {
    padding: 1.5rem;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    background-color: var(--neutral);
    border-top: 1px solid var(--neutral400);
}

.InviteUser footer button {
    @include mobile {
        padding: 1rem 1.5rem;
    }
}

.InviteUser footer button:disabled {
    opacity: 0.2;
}

.cancel {
    color: var(--neutral600);
    background-color: var(--neutral400);
}

.email {
    width: 100%;
}