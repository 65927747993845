@import "app/colours.css";
@import "./Form.module.css";

.RadioButtons {
    display: flex;
    flex-direction: column;
	margin-bottom: 1.5rem;
}

.customValue {
	margin-left: 2rem;
}

.radioControl {
    display: flex;
    flex-direction: row;
	margin: 0.5rem 0;
}

.radio {
	margin-right: 0.5rem;
}

.radio {
	-webkit-appearance: none;
	appearance: none;
	background: #fff; /* this is needed for iOS. Otherwise the background just becomes black. */
	border: 1px solid var(--neutral400);
	height: 1.5em;
	width: 1.5em;
	display: inline-block;
	border-radius: 100%;
	vertical-align: text-bottom;
	position: relative;
	
	&[disabled],
	&[aria-disabled=true] {
		opacity: .4;
	}
	
	&::before {
		content: '';
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		top: 0;
	}
	
	// &:focus {
	// 	outline: 2px solid;
	// 	outline-offset: 2px;
	// }
}

.radio::before {
	height: 0;
 	width: 0;
}

.radio:checked {
	border: 2px solid var(--main-tint);
}
	
.radio:checked::before {
	border: 6px solid transparent;
	border-radius: 100%;
	outline-offset: -6px;
	background: var(--main-tint);
}
