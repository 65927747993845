.TaskScreen {}

.method {
    font-size: 1rem;
    font-style: italic;
    white-space: pre-wrap;
}

.method p {
    margin: 0 0 0.5rem 0;
}