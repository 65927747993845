@import "app/colours.css";

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.item {
    display: flex;
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 12px rgba(36, 44, 60, 0.05);
    cursor: pointer;
}

.item > input {
    margin: 0 1rem 1rem 0;
}

.itemData {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.checked h3 {
    text-decoration: line-through;
}

.itemMethod {

}

.statusContent {
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 4px;
    margin-left: 0.5rem;
    padding: 4px 6px;
    color: white;
}

.overdue h3,
.due h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overdue .statusContent {
    background-color: var(--overdue-status-background);
}

.due .statusContent {
    background-color: var(--due-status-background);
}