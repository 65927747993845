.Modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(240, 240, 240, 0.95);
    z-index: 10;
}

.opaque {
    background: rgba(240, 240, 240, 1);
}

.ModalContent {
    margin: auto;
}