@import "app/colours.css";

.Button {
    border: 0;
    padding: 1rem 2.5rem;
    border-radius: 4px;
    background-color: var(--main-tint);
    color: #fff;
    font-weight: bold;
}

.iconButton {
    padding: 1rem 2.5rem 1rem 1rem;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
}

button.Button:disabled {
    background-color: var(--main-tint-light);
}
