@import "app/breakpoints.scss";
@import "LoginText.module.scss";

.QRCodeScanner p {
    max-width: 800px;
    text-align: center;
    margin: 1rem auto;
    text-wrap: balance;
}

.videoElement {
    display: block;
    margin: 0rem auto;
    width: 100%;
    @include tablet-portrait {
        width: 80vw;
        max-width: 1000px;
    }
}

button.cancel {
    display: block;
    margin: 1rem auto;
    color: var(--neutral600);
    background-color: var(--neutral400);
}