@import "~bootstrap/scss/bootstrap";

.loginScreen, .taskScreen {
    .nav-pills .nav-item {
        border: 1px solid var(--bs-nav-pills-link-active-bg);
        border-left: 0;
        border-radius: 0;
    }

    .nav-pills .nav-item:first-of-type {
        border-left: 1px solid var(--bs-nav-pills-link-active-bg);
        border-radius: 4px 0 0 4px;
    }

    .nav-pills .nav-item:last-of-type {
        border-radius: 0 4px 4px 0;
    }

    .nav-pills .nav-item:only-child {
        border-radius: 4px;
    }

    .nav-pills .nav-link {
        border-radius: 0;
        padding: 1rem 2rem;
    }
}

.loginScreen {
    .nav-pills .nav-item {
        text-align: center;
        width: 150px;
    }
}

// Fix to show the search clear button on iOS when used with input[type="search"]
input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;
  
    /* Add custom styles */
     height: 20px;
     width: 20px;
     display: block;
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;
  
    /* icon size */
    background-size: 20px;
  
  }