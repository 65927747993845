@import "app/colours.css";
@import "app/breakpoints.scss";

:root {
    --margin-left: 35px;
}

.Sidebar {
    background-color: #ccc;
}

.venueName {
    font-weight: bold;
    margin-left: var(--margin-left);

    @include tablet-portrait {
        display: none;
    }
}

.button {
    padding: 2em 0 0 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    margin-left: var(--margin-left);
    font-size: 1rem;
    color: var(--main-tint);
}

.activeButton {
    background-color: #aaa;
}

.button svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
}

/* Tint the SVG */
.button path {
    fill: var(--main-tint);
}