.ImageViewer {
    position: relative;
    max-width: 80vw;
    max-height: 80vh;
    text-align: center;
}

.ImageViewer img {
    max-width: 80vw;
    max-height: 80vh;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -15px;
    right: -15px;
}