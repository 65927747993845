@import "app/colours.css";

.GroupTemperatureList {}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.item {
    margin-bottom: 2rem;
    position: relative;
}

.item.overdue {
    padding: 0.5rem 0 0.5rem 0;
    border-radius: 4px;
}

.statusContent {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 4px;
    margin-left: 0.5rem;
    padding: 4px 6px;
    color: white;
}

.overdue .statusContent {
    background-color: var(--overdue-status-background);
}