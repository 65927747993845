@import "app/breakpoints";

.Row {
    display: flex;
    flex-direction: column;

    @include tablet-landscape {
        flex-direction: row;
    }
}

.Row > div:not(:last-of-type) {
    @include tablet-landscape {
        margin-right: 1em;
        flex-grow: 1;
    }
}

/* Two components */
.Row > div:first-child:nth-last-child(2) {
    @include tablet-landscape {
        width: 50%;
    }
}

.Row > div:first-child:nth-last-child(2) + div {
    @include tablet-landscape {
        width: 50%;
    }
}
/* END two components */

/* Three components */
.Row > div:first-child:nth-last-child(3) {
    @include tablet-landscape {
        width: 33%;
    }
}

.Row > div:first-child:nth-last-child(3) + div {
    @include tablet-landscape {
        width: 33%;
    }
}

.Row > div:first-child:nth-last-child(3) + div + div {
    @include tablet-landscape {
        width: 33%;
    }
}
/* END Three components */

.Row > div > * {
    width: 100%;
}