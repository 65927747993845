@import "app/colours.css";

.TextEntry {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.heading p {
    margin: 0;
}

.problem {
    border: 1px solid var(--problem);
}