@import "app/colours.css";
@import "app/breakpoints.scss";

.DiaryQueue {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: var(--neutral);
    overflow: auto;
    margin: auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 80vw;

    @include tablet-portrait {
        max-height: 95vh;
        max-width: 600px;
    }

    @include tablet-landscape {
        width: 80vw;
        max-height: 90vh;
    }
}

.heading {
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid var(--neutral400);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--neutral);
    z-index: 2;
}

.heading svg {
    width: 2rem
}

.content {
    padding: 1.5rem;
}

.entries {
    overflow: auto;
    max-height: 80vh;
}

.entries td {
    padding: 1rem;
}

.entry {
    display: flex;
}

.entry td:first-of-type {
    width: 300px;
}