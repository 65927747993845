@import "app/breakpoints.scss";

.PhotoUpload {
    display: flex;
    flex-direction: column;
}

.controls {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-direction: column;

    @include tablet-portrait {
        flex-direction: row;
    }
}

.controls input {
    width: 0px;
    height: 0px;
    visibility: hidden;
}

.controls label {
    border: 0;
    padding: 1rem 2.5rem;
    border-radius: 4px;
    background-color: var(--main-tint);
    color: #fff;
    font-weight: bold;
    text-align: center;
}

.controls button,
.controls label {
    height: 60px;
    width: 100%;
    margin-bottom: 1.5rem;
    
    @include tablet-portrait {
        width: 240px;
        margin-right: 2rem;
        margin-bottom: 0;
    }
}

.photoContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.close {
    position: absolute;
    top: -15px;
    right: 0;
}

.photo {
    width: 200px;
    margin-right: 1rem;
    cursor: pointer;
}

.photos {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
