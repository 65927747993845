@import "app/colours.css";

.StoredItemsList {
    margin-top: 2rem;
    background-color: var(--neutral400);
    border-radius: 4px;
}

.item {
    list-style: none;
    display: inline-flex;
    width: 100%;
    padding: 0;
    align-items: center;
}

.item:last-of-type {
    margin-bottom: 0;
}

.item li {
    flex-grow: 1;
    padding: 1rem;
    flex-basis: 30%;
}

.item li:last-child {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 10%;
}

.item li:last-child button {
    cursor: pointer;
    background-color: white;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--neutral700);
}

.issue {
    border-radius: 4px;
    padding: 0.25rem;
    background-color: red;
    color: white;
}

.fine {
    border-radius: 4px;
    padding: 0.25rem;
    background-color: var(--success-light-bg);
    color: var(--success-pressed);
}