.formGroup {
    margin-bottom: 2rem;
}

.formGroup label.required {
    font-weight: bold;
}

.formGroup label.required::after {
    content: " *";
    color: var(--error);
}

.error {
    margin-top: 1rem;
    font-weight: bold;
    color: var(--error);
}